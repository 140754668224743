import React, { Fragment } from 'react';
import ReactPlayer from 'react-player';

import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';
import { PortableText } from '@portabletext/react';

import arrow_icon from '../../assets/images/icon/arrow.svg';

import { useLocation, useParams } from 'react-router-dom';
import usePortfolio from '../../hooks/usePortfolio';
import usePortfolioNextPrevious from '../../hooks/usePortfolioNextPrevious';

const PortfolioDetails = () => {
  const { state } = useLocation();
  const params = useParams();
  const { portfolio } = usePortfolio(params.id);
  const project = state?.project || portfolio;
  const { nextPortfolio, prevPortfolio } = usePortfolioNextPrevious(
    project?._id
  );

  var settings = {
    dots: true,
    centerMode: false,
    autoplay: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  console.log(project);

  return (
    <Fragment>
      {/* Start Page Banner Area  */}
      <section className="breadcrumb-wrapper">
        <div className="inner-box">
          <div className="prev-button">
            <a href={`/portfolio/${prevPortfolio?._id}`}>
              <img className="svg" src={arrow_icon} alt="" />
              <span>Projeto anterior</span>
            </a>
          </div>
          <div className="portfolio-intro">
            <p>
              ano{' '}
              <a href="/" target="_blank">
                {project?.year}
              </a>
            </p>
          </div>
          <div className="next-button">
            <a href={`/portfolio/${nextPortfolio?._id}`}>
              <img className="svg" src={arrow_icon} alt="" />
              <span>Próximo projeto</span>
            </a>
          </div>
        </div>
      </section>
      {/* End Page Banner Area  */}

      {/* Start Portfolio Detail Area  */}
      <section className="portfolio-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              {project?.videoUrl ? (
                <ReactPlayer
                  url={project?.videoUrl}
                  playing
                  loop
                  playsinline
                  width="100%"
                  height="100%"
                  muted
                  controls
                  className="react-player"
                />
              ) : (
                <img src={project?.mainImage.asset.url} alt="" />
              )}
            </div>
            <div className="col-lg-6 d-flex">
              <div className="portfolio-detail-intro w-100">
                <span className="sub-heading">PROJETO</span>
                <h3>{project?.title}</h3>
                {project?.description && (
                  <PortableText value={project.description} />
                )}
                {project?.url && (
                  <a
                    href={project.url}
                    className="link mt-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="svg" src={arrow_icon} alt="" />
                    <i className="fas fa-globe"></i>
                    <span>veja mais</span>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Portfolio Detail Area  */}

      {/* Start Portfolio Slider Area  */}
      <section className="slider-area">
        <div className="container">
          <Slider {...settings}>
            {project?.projectImages?.map((image) => (
              <div className="item" key={image.asset._id}>
                <img src={image.asset.url} alt="" />
              </div>
            ))}
          </Slider>
        </div>
      </section>
      {/* End Portfolio Slider Area  */}
    </Fragment>
  );
};

export default PortfolioDetails;
