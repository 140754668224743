import React, { Fragment } from 'react';

import HeaderNavigation from '../components/HeaderNavigation/HeaderNavigation';
import FooterOne from '../components/Footer/FooterOne';
import PortfolioDetails from '../components/PortfolioDetails/PortfolioDetails';

const PortfolioPage = () => {
  return (
    <Fragment>
      <HeaderNavigation />
      <PortfolioDetails />
      <FooterOne />
    </Fragment>
  );
};

export default PortfolioPage;
