import React, { Fragment, useState, useRef, useEffect } from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import autoAnimate from '@formkit/auto-animate';

import link_icon from '../../assets/images/icon/link.svg';
import usePosts from '../../hooks/usePosts';

const PortfolioThree = () => {
  const parent = useRef(null);
  const [activeFilter] = useState('all');
  const { posts, loading } = usePosts();

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  if (loading) return <div>Carregando...</div>;

  return (
    <Fragment>
      {/* Start Portfolio Filter Style Three Area  */}
      <div className="portfolio-section">
        <div className="custom-container">
          {/* Start Portfolio Filter Navigatopm Area  */}
          <ul className="portfolio-filter-btn">
            <li
              className={activeFilter === 'all' ? 'filter active' : 'filter'}
              onClick={() => {}}
            >
              Todas
            </li>
          </ul>
          {/* End Portfolio Filter Navigatopm Area  */}
          <div className="row g-0 mt-4" ref={parent}>
            {posts.map((post) => (
              <div key={post._id} className="col-lg-6">
                <div className="single-portfolio-3 four ">
                  <img src={post.mainImage.asset.url} alt="" />
                  <div className="portfolio-hover-3">
                    <h3>{post.title}</h3>
                    <h6>{post.description}</h6>
                    <div className="line"></div>

                    <Link to={`/blog/${post._id}`}>
                      <img className="svg" src={link_icon} alt="" />
                      detalhes
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Portfolio Filter Style Three Area  */}
    </Fragment>
  );
};

export default PortfolioThree;
