import usePortfolios from '../hooks/usePortfolios';

const usePortfolioNextPrevious = (portfolioId) => {
  const { portfolios } = usePortfolios();
  if (!portfolios || portfolios.length === 0) return { portfolio: null };

  const index = portfolios.findIndex((p) => p._id === portfolioId);
  const nextIndex = index === portfolios.length - 1 ? 0 : index + 1;
  const prevIndex = index === 0 ? portfolios.length - 1 : index - 1;
  const nextPortfolio = portfolios[nextIndex];
  const prevPortfolio = portfolios[prevIndex];

  return { nextPortfolio, prevPortfolio };
};

export default usePortfolioNextPrevious;
