import { useState, useEffect } from 'react';
import client from '../sanityClient';

const usePosts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await client.fetch(`*[_type == "post"]{
          _id,
          title,
          postedAt,
          description,
          content,
          mainImage{
            asset->{
              _id,
              url
            }
          }       
        }`);
        setPosts(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { posts, loading, error };
};

export default usePosts;
