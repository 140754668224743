import { createClient } from '@sanity/client';

const client = createClient({
  projectId: '3yd861yc',
  dataset:
    process.env.NODE_ENV === 'development' ? 'development' : 'production',
  apiVersion: '2024-10-28', // Use today's date for the latest API version
  useCdn: process.env.NODE_ENV !== 'development', // Set to false for real-time content
});

export default client;
