import React from 'react';
import { Routes, Route } from 'react-router-dom';
import About from '../pages/AboutPage';
import HomePage from '../pages/HomePage';
import PortfolioPage from '../pages/Portfolio';
import HomePageThree from '../pages/HomePageThree';

const AppRoute = () => {
  return (
    <Routes>
      <Route path="*" element={<HomePage />} exact />
      <Route path={`${process.env.PUBLIC_URL}/`} element={<HomePage />} exact />
      <Route
        path={`${process.env.PUBLIC_URL}/about`}
        element={<About />}
        exact
      />
      <Route
        path={`${process.env.PUBLIC_URL}/portfolio/:id`}
        element={<PortfolioPage />}
        exact
      />
      <Route
        path={`${process.env.PUBLIC_URL}/blog`}
        element={<HomePageThree />}
        exact
      />
    </Routes>
  );
};

export default AppRoute;
