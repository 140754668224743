import React, { Fragment, useState, useRef, useEffect } from 'react';

import autoAnimate from '@formkit/auto-animate';
import { Link } from 'react-router-dom';

import arrow_icon from '../../assets/images/icon/arrow.svg';
import usePortfolios from '../../hooks/usePortfolios';
import useCategories from '../../hooks/useCategories';

const PortfolioOne = () => {
  const { portfolios } = usePortfolios();
  const { categories } = useCategories();
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const [activeFilter, setActiveFilter] = useState('all');

  const filteredPortfolios = portfolios.filter(
    (project) =>
      activeFilter === 'all' ||
      project.categories
        ?.map((category) => category.title)
        .includes(activeFilter)
  );

  return (
    <Fragment>
      {/* Start Portfolio Filter Style One Area  */}
      <div className="portfolio-section">
        <div className="custom-container">
          {/* Start Portfolio Filter Navigatopm Area  */}
          <ul className="portfolio-filter-btn">
            <li
              className={activeFilter === 'all' ? 'filter active' : 'filter'}
              onClick={() => {
                setActiveFilter('all');
              }}
            >
              Projetos
            </li>
            {categories.map((category) => (
              <li
                key={category.title}
                className={
                  activeFilter === category.title ? 'filter active' : 'filter'
                }
                onClick={() => setActiveFilter(category.title)}
              >
                {category.title}
              </li>
            ))}
          </ul>
          {/* End Portfolio Filter Navigatopm Area  */}

          <div className="row portfolio-content" ref={parent}>
            {filteredPortfolios.map((project) => (
              <div className="col-sm-6 col-lg-3" key={project.title}>
                <Link
                  to={`/portfolio/${project._id}`}
                  className="single-gallery h-100"
                  state={{ project }}
                >
                  <div className="single-inner w-100 h-100">
                    <img
                      style={{ objectFit: 'cover' }}
                      src={project.mainImage?.asset?.url}
                      alt=""
                    />
                    <div className="portfolio-hover">
                      <img className="svg" src={arrow_icon} alt="" />
                      <h3>{project.title}</h3>
                      <h6>{project.category}</h6>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Portfolio Filter Style One Area  */}
    </Fragment>
  );
};

export default PortfolioOne;
