import React from 'react';
import useAboutMe from '../../hooks/useAboutMe';

const SocialLinks = () => {
  const { aboutMe } = useAboutMe();
  return (
    <ul>
      <li>
        <a href={aboutMe?.instagram} target="_blank" rel="noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
      </li>
      <li>
        <a href={aboutMe?.behance} target="_blank" rel="noreferrer">
          <i className="fab fa-behance"></i>
        </a>
      </li>
      <li>
        <a href={aboutMe?.linkedin} target="_blank" rel="noreferrer">
          <i className="fab fa-linkedin-in"></i>
        </a>
      </li>
      <li>
        <a href={`mailto:${aboutMe?.email}`} target="_blank" rel="noreferrer">
          <i className="fa fa-envelope"></i>
        </a>
      </li>
    </ul>
  );
};

export default SocialLinks;
