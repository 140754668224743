import { useState, useEffect } from 'react';
import client from '../sanityClient';

const usePortfolio = (portfolioId) => {
  const [portfolio, setPortfolio] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!portfolioId) return;

      try {
        setLoading(true);
        const data = await client.fetch(
          `*[_type == "portfolio" && _id == $portfolioId]{
          _id,
          title,
          year,
          url,
          description,
          mainImage{
            asset->{
              _id,
              url
            }
          },
          coverImage{
            asset->{
              _id,
              url
            }
          },
          "videoUrl": video.asset->url,
          projectImages[]{
            asset->{
              _id,
              url
            }
          }
        }`,
          { portfolioId }
        );

        setPortfolio(data[0]); // Expecting a single document, so use the first item
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [portfolioId]);

  return { portfolio, loading, error };
};

export default usePortfolio;
