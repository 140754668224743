import React, { Fragment } from 'react';
import SocialLinks from '../../components/SocialLinks/SocialLinks';

const FooterOne = () => {
  return (
    <Fragment>
      {/* Start Footer One Area  */}
      <footer className="footer-area">
        <div className="footer-social">
          <SocialLinks />
        </div>
        {/* Start Footer Copyright  */}
        <p className="copyright">
          Fernanda Karen | <a href="/">Comunicação</a>
        </p>
        {/* End Footer Copyright  */}
      </footer>
      {/* Start Footer One Area  */}
    </Fragment>
  );
};
export default FooterOne;
