import { useState, useEffect } from 'react';
import client from '../sanityClient';

const usePortfolios = () => {
  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data =
          await client.fetch(`*[_type == "portfolio"] | order(orderRank){
          _id,
          title,
          year,
          url,
          description,
          mainImage{
            asset->{
              _id,
              url
            }
          },
          coverImage{
            asset->{
              _id,
              url
            }
          },
          "videoUrl": video.asset->url,
          orderRank,
          projectImages[]{
            asset->{
              _id,
              url
            }
          },
          categories[]->{
            title 
          }
        }`);
        setPortfolios(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { portfolios, loading, error };
};

export default usePortfolios;
