import React, { Fragment } from 'react';

import HeaderNavigation from '../components/HeaderNavigation/HeaderNavigation';
import FooterOne from '../components/Footer/FooterOne';
import PortfolioThree from '../components/PortfolioThree/PortfolioThree';

const HomePageThree = () => {
  return (
    <Fragment>
      <HeaderNavigation />
      <PortfolioThree />
      <FooterOne />
    </Fragment>
  );
};

export default HomePageThree;
