import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../../assets/images/logo.png';
import menu_icon from '../../assets/images/icon/menu4.svg';
import cross_icon from '../../assets/images/icon/cross.svg';
import SocialLinks from '../../components/SocialLinks/SocialLinks';

const HeaderNavigation = () => {
  const [addClass, setAddClass] = useState(false);

  const toggle = () => {
    setAddClass(!addClass);
  };

  const boxClass = ['offcanvus-menu', addClass ? 'open' : ''];
  const canvasClass = ['mask-overlay', addClass ? 'open' : ''];

  return (
    <>
      {/* Start Header Area  */}
      <header className="header-area">
        <div className="custom-container">
          <div className="row">
            <div className="col-6 col-lg-4">
              <div className="logo">
                <a href="/">
                  <img src={logo} alt="Logo" />
                </a>
              </div>
            </div>
            <div className="col-6 col-lg-8 d-flex justify-content-end mobile-pos">
              <div className="mainmenu">
                <ul id="navigation">
                  <li className="has-child">
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about">Sobre mim</NavLink>
                  </li>
                  {/*<li>*/}
                  {/*  <NavLink to="/blog">Matérias</NavLink>*/}
                  {/*</li>*/}
                </ul>
              </div>
              <div className="header-right-menu">
                <button className="menu-btn" onClick={toggle}>
                  <img src={menu_icon} alt="Hamburger" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* End Header Area  */}

      {/* Start Off-Canvus Menu Area  */}
      <div className={boxClass.join(' ')}>
        <div className="close-offcanvus" onClick={toggle}>
          <img className="svg" src={cross_icon} alt="" />
          <div className="close-text">
            <span>fechar</span>
          </div>
        </div>
        <ul>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>

          <li>
            <NavLink to="/about">Sobre mim</NavLink>
          </li>
          {/*<li>*/}
          {/*  <NavLink to="/blog">Matérias</NavLink>*/}
          {/*</li>*/}
        </ul>
        <div className="footer-social-light">
          <SocialLinks />
        </div>
      </div>
      <div className={canvasClass.join(' ')} onClick={toggle}></div>
      {/* End Off-Canvus Menu Area  */}
    </>
  );
};

export default HeaderNavigation;
