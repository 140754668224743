import React, { Fragment } from 'react';

import useAboutMe from '../../hooks/useAboutMe';

const About = () => {
  const { aboutMe, loading } = useAboutMe();

  if (loading) return <div>Loading...</div>;
  if (!aboutMe) return <div>No data found</div>;

  return (
    <Fragment>
      <section className="about-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <img src={aboutMe.mainImage.asset.url} alt="" />
            </div>
            <div className="col-lg-8 ">
              <div className="about-dtails-wrapper">
                <div className="about-dtails pt-2">
                  <h6>{aboutMe.title}</h6>
                  <p>{aboutMe.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default About;
