import { useState, useEffect } from 'react';
import client from '../sanityClient';

const useCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data =
          await client.fetch(`*[_type == "category"] | order(orderRank){
          _id,
          title
        }`);
        setCategories(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { categories, loading, error };
};

export default useCategories;
