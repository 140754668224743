import React, { Fragment } from 'react';

import HeaderNavigation from '../components/HeaderNavigation/HeaderNavigation';
import PageBanner from '../components/PageBanner/PageBanner';
import About from '../elements/About/About';
import FooterOne from '../components/Footer/FooterOne';

const AboutPage = () => {
  return (
    <Fragment>
      <HeaderNavigation />
      <PageBanner />
      <About />

      <FooterOne />
    </Fragment>
  );
};

export default AboutPage;
