import { useState, useEffect } from 'react';
import client from '../sanityClient';

const useAboutMe = () => {
  const [aboutMe, setAboutMe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAboutMe = async () => {
      try {
        setLoading(true);

        // Query to fetch the latest "aboutMe" document
        const data = await client.fetch(`
          *[_type == "aboutMe"] | order(_createdAt desc)[0]{
            title,
            description,
            mainImage{
              asset->{
                _id,
                url
              }
            },
            instagram,
            behance,
            linkedin
          }
        `);

        setAboutMe(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAboutMe();
  }, []);

  return { aboutMe, loading, error };
};

export default useAboutMe;
