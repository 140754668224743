import React, { Fragment } from "react";

import HeaderNavigation from "../components/HeaderNavigation/HeaderNavigation";
import PortfolioOne from "../components/PortfolioOne/PortfolioOne";
import FooterOne from "../components/Footer/FooterOne";

const HomePage = () => {
    return (
        <Fragment>
            <HeaderNavigation />
            <PortfolioOne />
            <FooterOne />
        </Fragment>
    );
};

export default HomePage;
